@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css?family=Inconsolata');

.App-logo {
    height: 15vmin;
    pointer-events: none;
}

.App-picture {
    height: 50vmin;
    width: auto;
    pointer-events: none;
}

.container {
    height: 100%;
    width: 100%;
}

.App-header {
    min-height: 15vh;
    display: flex;
    align-items: center;
}

.App-link {
    color: #61dafb;
}

#tsparticles {
    position: fixed;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    left: 0;
    top: 0;
    z-index: -1;
}

.responsive {
    text-decoration: none;
    color: white;
    font-style: italic;
}

.responsive:hover {
    color: plum;
    font-style: normal;
}
